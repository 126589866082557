.richText {
    :global {
        h1 {
            font-size: 1.8em;
            color: #0c2d69;
            text-align: center;
            width: 100%;
            margin: 20px 0px 20px 0px;
        }
        h2 {
            font-size: 1.5em;
            color: #0c2d69;
            text-align: center;
            line-height: 1.3em;
            font-weight: 700;
        }
        h3 {
            color: #0c2d69;
            font-size: 1.125em;
            line-height: 1.5em;
            font-weight: 600;
            margin: 10px 0px 10px 0px;
        }

        h4 {
            color: #fff;
            background: #0c2d69;
            font-size: 0.9375em;
            line-height: 1.6em;
            font-weight: bold;
            padding: 5px;
        }

        .topmenu {
            position: relative;
            float: right;
            /*width: 700px;*/
            margin-right: 0px;
            margin-top: 40px;
            z-index: 9999;
        }

        .langToggle {
            cursor: pointer;
        }
        #coverImage {
            position: relative;
            min-height: 250px;
        }
        #coverImage .preloadBackgorund {
            position: absolute;
            top: 0;
            left: 0;
        }
        #coverImage #kvSlider {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        /*------------------------------------------  Menu -----------------------------------------*/
        .mm-menu {
            background: transparent;
        }
        .mm-menu li a:hover {
        }
        .mm-menu .mm-listview > li:after {
            border-color: rgba(0, 0, 0, 0.3);
        }
        a {
            color: #0c2d69;
        }
        a:hover {
            text-decoration: underline;
        }

        .shortcutMenu a {
            color: #0c2d69;
            margin: 0px 5px 5px 5px;
            padding: 0px;
            font-size: 0.875em;
            line-height: 1em;
            border-bottom: 5px solid #fff;
        }

        .shortcutMenu a:hover {
            color: #0c2d69;
            transition:
                color 0.3s ease-in,
                background-color 0.3s ease-in;
            text-decoration: none;
        }

        .nav .open > a,
        .nav .open > a:focus,
        .nav .open > a:hover {
            background-color: #fff;
            border-color: #fecf13;
        }
        .nav > li > a:focus,
        .nav > li > a:hover {
            text-decoration: none;
            border-bottom: 5px solid #fecf13;
            background: #fff;
        }

        .navbar-nav > li > a {
            padding: 10px 5px;
            line-height: 1em;
        }
        .nav .megamenu-content {
            display: none;
        }
        .nav li.dropdown:hover > ul {
            display: block;
        }
        .img-swap {
        }

        .magamenu .dropdown .active {
            text-decoration: none;
            border-bottom: 5px solid #fecf13;
        }

        .ewin_logo_if {
            display: none;
        }
        .mobile-lang {
            margin-top: 20px;
        }
        #menuContent {
            background: #0c2d69;
        }
        .menufont-color {
            background: #ffffff;
        }
        .menufont-color a {
            color: #0c2d69;
            margin: 0px 0px 0px 0px;
            padding: 0px;
            font-size: 0.875em;
            line-height: 1em;
            border-bottom: 0px solid #e1e1e1;
        }
        .desktop-border a {
            border-bottom: 1px solid #e1e1e1;
        }
        .mm-listview {
            background-color: #ffe579;
            margin-left: -20px;
            margin-right: -20px;
        }
        .mm-listview .mm-listview li {
            padding-left: 20px;
        }
        .mm-listview > li > a,
        .mm-listview > li > span {
            color: #333;
            background: #fecf13;
        }
        .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 99999;
            display: none;
            float: left;
            min-width: 132px;
            padding: 0px 0;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ccc;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        }
        .dropdown-menu > li > a {
            display: block;
            padding: 5px 10px;
            clear: both;
            font-weight: 400;
            line-height: 2;
            color: #0c2d69;
            white-space: nowrap;
        }
        .dropdown-menu > li > a:focus,
        .dropdown-menu > li > a:hover {
            text-decoration: none;
            background: #fecf13;
        }
        .mm-menu .mm-listview > li .mm-next:after {
            margin-top: 6px;
            border-color: rgba(0, 0, 0, 0.5);
        }
        .bloggerArticlesList .dropdown-menu {
            width: 600px !important;
        }
        @media (max-width: 622px) {
            .bloggerArticlesList .dropdown-menu {
                width: 300px !important;
            }
        }

        .bloggerArticlesList .dropdown-menu > li > a {
            line-height: 1.42857143;
            white-space: normal;
        }
        .bloggerArticlesList .dropdown-menu li {
            border-bottom: 1px solid #ccc;
        }
        /*------------------------------------------  topvisual -----------------------------------------*/

        #sec-topvisual {
            background-size: cover;
            background-position: center top;
        }

        #sec-topvisual-in {
            /*background-image: url(../images/top-visual-in.jpg);*/
            background-size: cover;
            background-position: center top;
        }
        #sec-topvisual .floatContent {
            z-index: 8888;
        }
        .conghua {
            text-align: left;
            margin: 15% 0 15% 3%;
        }

        .conghua-m img {
            width: 100%;
            max-width: 767px;
        }

        .conghua-in {
            text-align: left;
            margin: 12% 0 12% 3%;
        }
        .conghua-in-m img {
            width: 100%;
            max-width: 767px;
        }

        #sec-topvisual-in > .container {
            position: relative;
            height: 480px;
        }

        .topvisual {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 40%;
            height: 50%;
            padding: 20px;
            background: red;
            color: white;
            text-align: center;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        }

        .topvisual-container {
            position: absolute;
            top: 45%;
            left: 0%;
            width: 498px;
            transform: translate(0, -50%);
            color: #fff;
            margin: 0 auto;
            margin-left: 20px;
        }
        .topvisual-container img {
            width: 100%;
        }

        .topvisual-arrow {
            position: absolute;
            top: 95%;
            left: 50%;
            width: 498px;
            transform: translate(0, -50%);
            color: #fff;
            margin: 0 auto;
        }

        /*------------------------------------------  CEO -----------------------------------------*/

        #sec-quote {
            // background-image: url(../images/quote-bg.jpg);
            background-size: cover;
            background-position: center bottom;
        }

        .ceo-quote {
            text-align: center;
        }

        .ceo-quote img {
            width: 100% !important;
            max-width: 1036px !important;
        }

        /*------------------------------------------  About -----------------------------------------*/
        #sec-about {
            background: #cccccc;
        }
        #sec-about-in {
            background: #f3f3f3;
        }
        .sec-bg-white {
            padding-bottom: 15px;
            background: #ffffff;
        }
        .sec-bg-gray {
            padding-bottom: 15px;
            background: #f3f3f3;
        }
        .skew-container {
            padding: 40px 20px;
            transform: skew(-15deg);
            background-color: #ebebeb;
            height: 260px;
            text-align: center;
        }
        .skew-container-mid {
            padding: 40px 20px;
            transform: skew(-15deg);
            background-color: #f5f5f5;
            height: 260px;
            text-align: center;
        }

        .skew-container h2,
        .skew-container p,
        .skew-container-mid h2,
        .skew-container-mid p {
            transform: skew(10deg);
            text-align: center;
        }

        .skew-container h2,
        .skew-container-mid h2 {
            color: #0c2d69;
            border-bottom: 7px solid #fecf13;
            transform: skew(15deg);
            display: inline-block;
        }

        .num {
            font-size: 1.8em;
            display: inline;
        }

        .skew-container p,
        .skew-container-mid p {
            margin: 0 auto;
            width: 90%;
            color: #333;
            margin-top: 5px;
            margin-bottom: 20px;
            transform: skew(15deg);
            display: inline-block;
        }

        .btn-more {
            // background: url(../images/more-arrow.png) no-repeat;
            padding-left: 15px;
            margin: 0 auto;
            display: inline-block;
            font-weight: bold;
        }

        .skew-btn {
            transform: skew(15deg);
            display: inline-block;
        }

        #sec-about > a {
            position: relative;
            text-decoration: none;
        }

        .btn-more a:hover {
            text-decoration: underline;
        }

        .yellow-bg {
            background: #fecf13;
            margin-top: 50px;
            margin-bottom: 0px;
        }

        .skew-container-in {
            padding: 40px 20px;
            text-align: center;
        }

        .skew-container-in h2 {
            color: #0c2d69;
            border-bottom: 7px solid #ebebeb;
            display: inline-block;
            margin: 0 auto;
        }

        .skew-container-in p {
            width: 80%;
            margin: 0 auto;
            padding: 20px 0px;
        }

        /*------------------------------------------  Video -----------------------------------------*/

        .newDetailListOn {
            background: #5170a9;
        }
        .newDetailListOn a {
            color: #fff;
        }

        .widgetTable a {
            padding-left: 5px;
        }
        .videoThumbList {
            text-align: center;
            min-height: 300px;
        }
        .highlightHolder,
        .videoSubTitle {
            display: none;
        }
        .thumbnailHolder {
            display: none;
            /*float:none;*/
            margin: 0 auto;
        }
        .blue-bg {
            background: #0c2d69;
            padding: 50px 0px;
        }

        #sec-video {
            // background-image: url(../images/video-bg.jpg);
            background-size: cover;
            background-position: center bottom;
            padding: 50px 0px;
        }

        .index-video {
            margin: 0 auto;
            text-align: center;
            padding-top: 0px;
        }

        .index-video img {
            /*width:100%;*/
            max-width: 496px;
        }
        .video-photo {
            height: 300px;
            margin-top: 50px;
        }
        .video-play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 20px;
            color: #fecf13;
            text-align: center;
        }

        .video-play p {
            margin-top: 20px;
        }

        .btn-aerial-view {
            // background: url(../images/download-arrow.png) no-repeat;
            padding-left: 30px;
            font-weight: bold;
            text-align: left;
            display: inline-block;
        }
        .btn-aerial-view a,
        .btn-other-video a {
            color: #fecf13;
            text-decoration: none;
        }

        .btn-aerial-view a:hover,
        .btn-other-video a:hover {
            text-decoration: underline;
        }

        .btn-other-video {
            // background: url(../images/btn-video-arrow.png) no-repeat;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
            display: inline-block;
        }

        .video-container {
            padding: 30px 0px 0px 0px;
            width: 490px !important;
        }

        .video-container-in {
            width: 860px;
            padding: 30px 0px;
        }

        .video-big {
            text-align: center;
            margin-bottom: 30px;
        }

        .video-big img {
            width: 100%;
            max-width: 860px;
            background: #fff;
            padding: 10px;
        }

        .video-index {
            text-align: center;
            margin-bottom: 0px;
        }

        .video-index img {
            width: 100%;
            max-width: 490px;
            border: 1px solid #333;
        }

        .video-thumbnail img {
            width: 100%;
            max-width: 276px;
        }

        .video-thumbnail p {
            color: #fff;
            line-height: 1.2em;
            padding: 5px 0px 20px 0px;
        }

        .video-big-play {
            position: absolute;
            z-index: 100;
            top: 50%;
            left: 50%;
            margin-top: -70px;
            margin-left: -50px;
        }
        .video-big-play img {
            width: 100px;
            height: 100px;
            background: transparent;
        }

        .video-big-play-in {
            position: absolute;
            z-index: 100;
            top: 20%;
            left: 50%;
            margin-top: -70px;
            margin-left: -50px;
        }
        .video-big-play-in img {
            width: 100px;
            height: 100px;
            background: transparent;
        }

        .video-big-text-in {
            width: 100%;
            padding: 5px 10px;
            color: #0c2d66;
            text-align: left;
        }

        .video-big-text {
            width: 300px;
            position: absolute;
            z-index: 100;
            top: 50%;
            left: 50%;
            margin-top: 30px;
            margin-left: -150px;
            background: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            color: #fff;
        }

        .video-index-play {
            position: absolute;
            z-index: 100;
            top: 50%;
            left: 50%;
            margin-top: -40px;
            margin-left: -25px;
        }
        .video-index-play img {
            width: 50px;
            height: 50px;
            background: transparent;
            border: 0px;
        }

        .video-index img {
            position: relative;
            text-align: center;
            color: white;
        }

        .video-index-text {
            width: 90%;
            max-width: 400px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            margin-top: 40px;
            margin-left: 0px;
            z-index: 100;
            background: rgba(0, 0, 0, 0.5);
            padding: 3px 5px;
            color: #fff;
            line-height: 20px;
        }

        a > .video-thumbnail-play {
            position: relative;
            z-index: 999;
            margin-top: -55px;
            margin-right: 8px;
            color: #fff;
            float: right;
            width: 51px;
            height: 50px;
            // background-image: url(../images/video-play-off.png);
            background-repeat: no-repeat;
        }

        a:hover > .video-thumbnail-play {
            position: relative;
            z-index: 999;
            margin-top: -55px;
            margin-right: 8px;
            color: #fff;
            float: right;
            width: 51px;
            height: 50px;
            // background-image: url(../images/video-play-on.png);
            background-repeat: no-repeat;
        }

        .videoSubTitle {
            color: #0c2d69;
            font-size: 1.125em;
            line-height: 1.5em;
            font-weight: 600;
            margin: 10px 0px 10px 0px;
            text-align: left;
        }

        #videoList .dropdown-menu > li > a {
            width: 280px;
        }

        .hkjc-album .dropdown-menu > li > a {
            width: 280px;
        }

        /*------------------------------------------  news -----------------------------------------*/
        #sec-news {
            background: #fcd01e;
            padding: 50px 0px 40px 0px;
            text-align: left;
        }

        #sec-news p {
            margin-bottom: 30px;
        }

        .widgetTable tr {
            border-bottom: 1px solid #ccc;
            display: block;
            float: left;
            min-height: 50px;
            max-height: 150px;
            overflow: hidden;
            width: 100%;
        }

        #news .newDetailListOn {
            background: #f3f3f3;
        }
        .date-list-container {
            width: 100px;
            padding-right: 20px;
            display: inline-block;
        }

        .date-list-dropdown {
            max-width: 100px;
            right: 0;
            left: auto;
            display: inline-block;
            margin-bottom: 0;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        /*------------------------------------------  Timeline -----------------------------------------*/
        #sec-timeline {
            /*background-image: url(../images/timeline-bg.jpg);
  background-size: cover;
  background-position: center bottom;
  height: 1160px;*/
            padding: 30px 0px 50px 0px;
            background: #ebebeb;
        }

        #sec-timeline-in {
            background: #f3f3f3;
        }

        .text-left {
            text-align: left;
        }
        .text-right {
            text-align: right;
        }
        .text-center {
            text-align: center;
        }
        .cd-timeline-content img {
            max-width: 400px;
            width: 100%;
        }

        /*------------------------------------------  FAQ -----------------------------------------*/
        #sec-faq {
            background: #0c2d69;
            padding: 25px 0px 40px 0px;
        }
        #sec-faq > .container {
            padding: 0px 200px;
        }

        #sec-faq-in {
            padding-bottom: 0px;
            background: #f3f3f3;
        }
        #sec-faq h2 {
            color: #fff;
            padding-bottom: 20px;
        }
        .nex-pre-arrow img {
            width: 100%;
            max-width: 23px;
            margin-top: 20px;
        }
        #sec-faq h3 {
            color: #fcd01e;
            text-align: left;
            border-bottom: 1px solid #65799d;
            padding-bottom: 10px;
            width: 90%;
            margin: 0 auto;
        }
        #sec-faq p {
            color: #fff;
            text-align: left;
            width: 90%;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .panel-title {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 1em;
            color: inherit;
            padding: 10px 10px;
            font-weight: 400 !important;
        }

        .panel-group .panel {
            border-radius: 0px;
        }
        .panel-body {
            background: #fcd01e;
        }

        .list-num {
            color: #999;
            float: left;
            width: 7%;
        }
        .list-q {
            float: left;
            width: 93%;
        }

        .arrow-down {
            display: block;
            float: right;
            text-align: right;
        }

        .arrow-down img {
            width: 60%;
        }

        .btn-other-faq a {
            color: #fecf13;
            text-decoration: none;
        }

        .btn-other-faq a:hover {
            text-decoration: underline;
        }

        .btn-other-faq {
            // background: url(../images/btn-video-arrow.png) no-repeat;
            padding-left: 15px;
            margin: 0 auto;
            font-weight: bold;
            text-align: left;
            display: inline-block;
        }

        /*------------------------------------------  download -----------------------------------------*/
        #sec-download {
            background: #fcd01e;
            padding: 20px 0px;
            text-align: center;
        }

        .btn-download {
            // background: url(../images/icon-download.png) no-repeat;
            padding-left: 70px;
            margin: 0 auto;
            font-weight: bold;
            text-align: left;
            height: 100px;
            line-height: 100px;
            display: inline;
            padding: 20px 0px 20px 70px;
        }

        .btn-download a {
            color: #0c2d69;
        }

        .btn-download a:hover {
            text-decoration: underline;
        }
        /*----------------video background--------------------*/
        #sec-topvisual {
            position: relative;
            overflow: hidden;
            height: 35.8vw;
        }
        #sec-topvisual .floatContent {
            z-index: 8888;
        }
        #sec-topvisual #filterForVideo {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 8888;
            // background: url(images/filter-video.png);
        }
        #backgroundVideo-Container {
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        #backgroundVideo-Container .video-overflow {
            text-align: center;
            overflow: hidden;
        }
        #backgroundVideo-Container .video-overflow video {
            width: 100%;
            height: 100%;
        }

        #videoList .widgetTable tr {
            padding-top: 10px;
        }

        #videoList .widgetTable a {
            padding-left: 5px;
        }

        /*----------------social--------------------*/
        .social-margin {
            margin-top: 40px;
        }

        .social-margin-news {
            margin-top: 16px;
        }

        #sidebar {
            margin-top: 15px;
        }

        .hkjc-album #sidebar {
            margin-top: 0px;
        }

        #videoList #sidebar {
            margin-top: 0px;
        }

        .btn-primary {
            border-color: #0c2d69;
            width: 100%;
        }

        /*----------------table--------------------*/
        .table > tbody > tr > td,
        .table > tbody > tr > th,
        .table > tfoot > tr > td,
        .table > tfoot > tr > th,
        .table > thead > tr > td,
        .table > thead > tr > th {
            padding: 8px;
            line-height: 1.42857143;
            vertical-align: top;
            border-bottom: 1px solid #ddd;
        }
        .table-striped > tbody > tr:nth-of-type(odd) {
            background-color: #f1f1f1;
        }
        .table-striped > tbody tr:nth-child(even),
        .raceresult-table tbody tr:nth-child(even) .post-number td {
            /*background: #ede9e3!important;*/
            background: #ffffff !important;
        }

        .hkjc-album .widgetTable tr {
            padding-top: 10px;
        }

        .hkjc-album .widgetTable a {
            padding-left: 5px;
        }
        /*----------------album--------------------*/
        .hkjc-album .containerBackground {
            padding-top: 0px;
        }

        .hkjc-album .btn-primary {
            width: 100% !important;
        }

        .hkjc-album .newDetailListOn {
            background: #5170a9 !important;
        }
        .hkjc-album .newDetailListOn a {
            color: #fff;
        }
        /*----------------button--------------------*/
        .btn-ctc {
            background-color: #ffcb05;
            color: #0c2d69;
            font-weight: 700;
            border-radius: 50px;
            font-size: 1.8em;
            padding: 10px 30px;
            margin-top: 15px;
            margin-bottom: 15px;
            white-space: normal;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
        }
        .btn-ctc.focus,
        .btn-ctc:focus,
        .btn-ctc:hover {
            color: #0c2d69;
            background-color: #fdb300;
            border-color: #fdb300;
        }

        /* === Raceinfo icon CC ===*/
        /*race*/

        .racing-move-left {
            margin-left: 0px !important;
        }

        .raceinfo-icon-title {
            width: 68%;
            float: left;
            line-height: 1.5em;
            font-size: 0.85em;
            padding-left: 4px;
        }

        .raceinfo-icon,
        .raceinfo-icon-title {
            margin-top: 10px;
            font-weight: 400;
        }

        .raceinfo-icon {
            width: auto;
            float: left;
        }

        .race-icon {
            margin-right: 0px;
            margin-left: 0px;
            width: 40px;
            height: 40px;
            background: #002566;
            float: left;
            margin-right: 5px;
            margin-bottom: 5px !important;
            border-radius: 3px;
        }

        .next-line {
            display: block;
            font-size: 13px;
            line-height: 16px;
        }
        .raceinfo-icon-m li {
            margin-top: 8px;
            float: left;
        }

        .raceinfo-icon-m ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;
        }

        .gap {
            display: none;
        }

        @media (max-width: 1199px) {
            .next-line {
                font-size: 10.5px;
            }
        }

        @media (max-width: 1024px) {
            .raceinfo-icon {
                width: auto;
            }
            .raceinfo-icon-title {
                font-size: 0.875em;
                width: auto;
            }
        }
        @media (max-width: 991px) {
            .raceinfo-br-none {
                display: none;
            }

            .racing-move-left {
                margin-left: 0px !important;
            }

            .racing-mobile-width.vc_col-sm-11 {
                width: 100%;
            }
            .cup-cc,
            .raceinfo-icon {
                display: none;
            }
            .raceinfo-icon-m li {
                margin-top: 0px;
                border-bottom: 1px dotted #ccc;
                width: 100%;
                display: block;
                margin-bottom: 10px !important;
            }

            .raceinfo-icon-title {
                margin-top: 0px;
                margin-bottom: 0px;
                border-bottom: 1px solid #ccc;
                width: 100% !important;
            }

            .raceinfo-icon,
            .raceinfo-icon-title {
                margin-top: 0px;
            }
        }

        @media (max-width: 991px) {
            .next-line {
                display: inline;
                font-size: 1em;
            }

            .gap {
                display: inline;
                margin-right: 5px;
            }
        }

        .race-btn {
            width: 19.6%;
            display: block;
            background-color: #e1e1e1;
            display: inline-block;
            text-align: center;
            padding: 10px;
        }

        .race-btn.active {
            background-color: #002566;
            color: #fff;
            display: inline-block;
        }

        a:hover .race-btn {
            background-color: #e1e1e1;
            color: #002566;
            display: inline-block;
        }

        .race-btn-bar {
            width: 100%;
        }

        .raceinfo-icon-m {
            margin-top: 10px;
        }

        .race-info-bar {
            margin-top: 10px;
        }

        @media (max-width: 992px) {
            .race-btn {
                width: 19.4%;
            }
        }

        @media (max-width: 800px) {
            .race-btn {
                width: 19.3%;
            }
        }

        @media (max-width: 510px) {
            .race-btn {
                width: 19%;
            }
        }

        @media (max-width: 414px) {
            .race-btn {
                font-size: 14px;
                padding: 10px 2px;
                width: 18.6%;
            }
        }

        @media (max-width: 375px) {
            .race-btn {
                font-size: 13px;
                padding: 10px 2px;
                width: 18.3%;
            }
        }

        @media (max-width: 360px) {
            .race-btn {
                font-size: 12px;
                padding: 10px 2px;
                width: 18.2%;
            }
        }

        @media (max-width: 767px) {
            h1.new-page-title {
                color: #266632;

                display: none;
            }
            #sec-topvisual {
                height: 107.8vw;
            }
        }

        /* === Raceinfo icon CC end ===*/
        .content-hr {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        /* === start card  ===*/
        .card-bottom-space {
            margin-bottom: 30px;
        }
        .card-container {
            padding: 20px 30px 30px 30px;
            background-color: #ebebeb;
            height: 100%;
        }
        .card-name {
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            font-size: 1.8em;
            color: #0c2d69;
            text-align: center;
            width: 100%;
            font-weight: 700;
            padding-bottom: 20px;
        }
        .card-photo {
            position: relative;
        }
        .card-info {
            position: absolute;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            color: #ffffff;
            font-size: 1em;
            line-height: 1.5em;
            width: 100%;
            text-align: center;
            padding: 5px;
        }
        .card-info-top {
            position: absolute;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            color: #ffffff;
            font-size: 1em;
            line-height: 1.5em;
            width: 100%;
            text-align: center;
            padding: 5px;
        }
        .card-flag {
            width: 32px;
        }
        .card-text {
            margin-top: 25px;
            color: #000000;
            font-size: 0.875em;
            line-height: 1.7em;
        }

        /* === end card  ===*/

        /* === start behind-the-scenes video card  ===*/
        .video-card-bottom-space {
            margin-bottom: 30px;
        }
        .video-card-container {
            position: relative;
            padding: 0px 0px 15px 0px;
            background-color: #ebebeb;
            height: 100%;
        }
        .video-card-name {
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            font-size: 1.8em;
            color: #0c2d69;
            text-align: center;
            width: 100%;
            color: #0c2d69;
            font-weight: 700;
            padding-top: 25px;
            padding-bottom: 15px;
            border-bottom: 1px solid #0c2d69;
        }
        .video-card-photo {
            position: relative;
        }
        .video-info-container {
            padding-left: 25px;
            padding-right: 25px;
        }
        .video-card-flag {
            width: 32px;
        }
        .video-card-text {
            color: #000000;
            font-size: 0.938em;
            line-height: 1.7em;
            margin: 15px 0px 0px 0px;
            text-align: center;
            min-height: 94px;
        }
        .video-btn {
            text-align: center;
        }

        /* === start behind-the-scenes video end card  ===*/

        /* === add table mobile hand icon ===*/
        .arial {
            font-family: arial !important;
        }

        .handIconNode {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background: rgba(39, 47, 56, 0.2)
                url(http://ctr-campaigns.hkjc.com/devtesting/wp-content/uploads/sites/11/hand.png)
                center top no-repeat;
            background-position: 50% 5%;
            -webkit-transition: opacity 0.15s;
            -o-transition: opacity 0.15s;
            transition: opacity 0.15s;
            -webkit-background-size: 50% 160px;
            background-size: 60px;
        }

        .responsive-table-container table {
            text-align: left;
        }
        .responsive-table-container table th {
            text-align: left;
        }

        .am-table {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            /*margin-bottom: 35px;*/
            margin-top: 0px;
            letter-spacing: 0px;
            font-size: 0.9125em;
            white-space: nowrap;
        }

        .wrap {
            white-space: normal;
        }
        .nowrap {
            white-space: nowrap;
        }

        .wrap P {
            line-height: 11px;
        }

        .am-table:last-child {
            margin-bottom: 0;
        }

        .am-table table th {
            background: #0c2d69; /*desigher update the color */
            color: #fff;
            line-height: 1.2em;
            text-transform: capitalize;
            padding: 10px 0px;
            letter-spacing: 0px;
        }

        .am-table table th,
        .am-table table td {
            padding-left: 5px;
            line-height: 18px;
        }

        .am-table.wrap table th,
        .am-table.wrap table td {
            padding: 5px;
        }

        .entry-content td,
        .comment-content td,
        .post-number td {
            border-top: 0px;
            border-bottom: 0px;
        }

        .entry-content td,
        .comment-content td {
            padding: 6px 10px 6px 5px;
        }

        .entry-content table,
        .post-number td {
            border-top: 0px;
            border-bottom: 0px;
        }

        .entry-content table {
            margin: 0px;
        }

        table tbody tr:nth-child(even),
        .raceresult-table tbody tr:nth-child(even) .post-number td {
        }

        table .post-number {
            margin: 0 auto;
            text-align: center;
        }

        .num-center {
            text-align: center !important;
            padding-left: 3px !important;
            padding-right: 3px !important;
        }

        .hand-icon.pastwinners table th:nth-child(1),
        .hand-icon.pastwinners table th:nth-child(5) {
            text-align: center !important;
        }
        .hand-icon.pastwinners table td:nth-child(1),
        .hand-icon.pastwinners table td:nth-child(5) {
            text-align: center !important;
        }

        .hand-icon.entries table td:nth-child(2) {
            text-align: center !important;
        }
        .hand-icon.entries table th:nth-child(2) {
            text-align: center !important;
        }

        .hand-icon.racecard table th:nth-child(1),
        .hand-icon.racecard table th:nth-child(3),
        .hand-icon.racecard table th:nth-child(5),
        .hand-icon.racecard table th:nth-child(7) {
            text-align: center !important;
        }
        .hand-icon.racecard table td:nth-child(1),
        .hand-icon.racecard table td:nth-child(3),
        .hand-icon.racecard table td:nth-child(5),
        .hand-icon.racecard table td:nth-child(7) {
            text-align: center !important;
        }

        .result table th:nth-child(1),
        .result table th:nth-child(2),
        .result table th:nth-child(6),
        .result table th:nth-child(7),
        .result table th:nth-child(8),
        .result table th:nth-child(9),
        .result table th:nth-child(10) {
            text-align: center !important;
        }
        .result table td:nth-child(1),
        .result table td:nth-child(2),
        .result table td:nth-child(6),
        .result table td:nth-child(7),
        .result table td:nth-child(8),
        .result table td:nth-child(9),
        .result table td:nth-child(10) {
            text-align: center !important;
        }

        @media only screen and (max-width: 1199px) {
            .isTouch .handIconNode {
                display: none;
            }
        }

        /* Added 27062019 Specific Hanlde Top Banner */
        /* below 767 */
        .conghua-m .aboutus,
        .conghua-m .news,
        .conghua-m .videos,
        .conghua-m .album,
        .conghua-m .behind-the-scenes,
        .conghua-m .introduction,
        .conghua-m .jockeys,
        .conghua-m .race_info,
        .conghua-m .trainers {
            display: none;
        }

        .Section-aboutus .conghua-m .aboutus,
        .Section-news .conghua-m .news,
        .Section-videos .conghua-m .videos,
        .Section-album .conghua-m .album,
        .Section-behind-the-scenes .conghua-m .behind-the-scenes,
        .Section-introduction .conghua-m .introduction,
        .Section-jockeys .conghua-m .jockeys,
        .Section-race_info .conghua-m .race_info,
        .Section-trainers .conghua-m .trainers {
            display: block;
        }

        /* below 767 */

        /* above 767 */
        .Section-aboutus #sec-topvisual-in {
            // background-image: url(../images/top-visual-in-aboutus.jpg);
        }

        .Section-news #sec-topvisual-in {
            // background-image: url(../images/top-visual-in-news.jpg);
        }

        .Section-videos #sec-topvisual-in {
            // background-image: url(../images/top-visual-in-videos.jpg);
        }

        .Section-album #sec-topvisual-in {
            // background-image: url(../images/top-visual-in-album.jpg);
        }

        .Section-introduction #sec-topvisual-in,
        .Section-race_info #sec-topvisual-in,
        .Section-jockeys #sec-topvisual-in,
        .Section-trainers #sec-topvisual-in,
        .Section-behind-the-scenes #sec-topvisual-in {
            // background-image: url(../images/top-visual-in-conghua.jpg);
        }

        @media (max-width: 1200px) {
            .Section-introduction #sec-topvisual-in,
            .Section-race_info #sec-topvisual-in,
            .Section-jockeys #sec-topvisual-in,
            .Section-trainers #sec-topvisual-in,
            .Section-behind-the-scenes #sec-topvisual-in {
                // background-image: url(../images/top-visual-in-conghua-1200.jpg);
            }
        }

        @media (max-width: 991px) {
            .Section-introduction #sec-topvisual-in,
            .Section-race_info #sec-topvisual-in,
            .Section-jockeys #sec-topvisual-in,
            .Section-trainers #sec-topvisual-in,
            .Section-behind-the-scenes #sec-topvisual-in {
                // background-image: url(../images/top-visual-in-conghua-991.jpg);
            }
            .menufont-color a {
                border-bottom: 0px solid #e1e1e1;
            }
            .menufont-color {
                background: #ffe579;
            }
        }

        /* above 767 */
        /* Added 27062019 Specific Hanlde Top Banner */

        div#socialutility a:nth-child(1) {
            display: none !important;
        }
        span.fa.fa-weibo {
            display: none !important;
        }

        table tbody tr td {
            border-color: #808080;
            border-width: 1px;
        }
        table tbody tr {
            border-color: #808080;
            border-width: 1px;
        }
    }
}
