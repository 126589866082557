.commingling-map-preview-image-root {
    :global {
        .ant-image-preview-img {
            max-height: none;
        }
    }
}

.imageGroupWrap {
    :global {
        .ant-image-preview-footer {
            .ant-image-preview-progress {
                display: none;
            }
        }

        .ant-image-preview-mask {
            background-color: rgb(0 0 0 / 90%) !important;
        }
    }
}

.imagePreviewWrap {
    max-height: 85vh;
    max-width: 85vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    :global {
        .ant-image-preview-img {
            max-height: 85vh;
            max-width: 85vw;
            object-fit: contain;
        }
    }
}

.imageRootClass {
    :global {
        .ant-image-preview-mask {
            background-color: rgb(0 0 0 / 90%) !important;
        }
    }
}
