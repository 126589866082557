.HighlightItem {
    background: #ebebeb;
    padding: 30px 0px 60px 0px;
    :global {
        .img-box {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 10px;
        }

        .highlight_img:hover {
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
            transition:
                transform 0.5s,
                -webkit-transform 0.5s;
            opacity: 1;
        }
        .RowContainer {
            display: flex;
            justify-content: center;
        }
        .box-RowContainer img {
            width: 100%;
        }
        .highlight_img {
            transition: 1s;
            -moz-transition: 1s;
            -webkit-transition: 1s;
            -o-transition: 1s;
            opacity: 0.97;
        }
        .picbox {
            display: block;
            overflow: hidden;
            -webkit-transition: opacity 2s ease-in-out;
            -moz-transition: opacity 2s ease-in-out;
            -o-transition: opacity 2s ease-in-out;
            transition: opacity 2s ease-in-out;
            opacity: 1;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)';
            filter: alpha(opacity=1);
        }

        .focusImageTitle {
            position: absolute;
            bottom: 0px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            color: white;
            font-size: 1em;
            line-height: 1.3em;
            padding: 5px 15px 10px 15px;
        }

        .focusTitle {
            display: inline-block;
        }

        .picbox_con {
            display: block;
            overflow: hidden;
            -webkit-transition: opacity 2s ease-in-out;
            -moz-transition: opacity 2s ease-in-out;
            -o-transition: opacity 2s ease-in-out;
            transition: opacity 2s ease-in-out;
            opacity: 1;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)';
            filter: alpha(opacity=1);

            -webkit-box-shadow: 5px 5px 5px 0px rgba(50, 50, 50, 0.3);
            box-shadow: 5px 5px 5px 0px rgba(50, 50, 50, 0.3);
            margin-bottom: 20px;
        }
        .focusImage {
            position: relative;
            float: left;
        }

        #home-highlight {
            background: #ebebeb;
            height: auto;
            padding: 30px 0px 60px 0px;
        }
    }
}
