.playIcon {
    width: 51px;
    height: 50px;
    background-repeat: no-repeat;
    position: absolute;
    right: 8px;
    bottom: 16px;
}

.imageWrap {
    position: relative;
    .playIcon {
        background-image: url('/images/video-play-off.png');
    }
}
.imageWrap:hover {
    position: relative;
    .playIcon {
        background-image: url('/images/video-play-on.png');
    }
}

.videoCloseIcon {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-image: url('/images/cross.png');
}

// .dialogWrap {
// :global {
// .ant-modal-content .ant-modal-close {
//     width: fit-content;
//     height: fit-content;
// }
// }
// }
