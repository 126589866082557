.pageArrowLeft,
.pageArrowRight {
    padding: 10px 19px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    color: #0c2d69;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    span {
        font-size: 19.5px;
        line-height: 20px;
        padding-bottom: 4px;
    }
}

.pageArrowLeft {
    position: absolute;
    left: 10px;
}

.pageArrowRight {
    position: absolute;
    right: 10px;
}

.pageSelectWrap {
    :global {
        .ant-dropdown-menu-title-content {
            justify-content: center;
        }
        .ant-dropdown-menu {
            padding: 0;
            .ant-dropdown-menu-item {
                padding: 0;
            }
        }
    }
}

.pageListText {
    width: 100%;
    padding: 2px 0;
    color: #333;
    font-weight: bold;
    text-align: center;
}
