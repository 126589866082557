.selectWrap {
    :global {
        .ant-select-selector {
            padding: 0 18px !important;
        }
    }
}

.selectDropWrap {
    padding: 4px 0;
    :global {
        .ant-select-item-option:not(:last-child) {
            border-bottom: 1px solid #ebebeb;
        }
    }
}
