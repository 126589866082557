.richText {
    :global {
        body .no-padding {
            margin: 0;
            padding: 0;
        }

        /* FOLLOW US */
        .followUs {
            /* text-align: left;*/
            display: block;
            vertical-align: center;
            margin: 10px 0 0 20px;
        }

        .followUs p {
            margin: 0;
            font-size: 1em;
        }

        .followUs a .fa {
            font-size: 2em;
            color: #fff;
            margin: 5px;
        }

        ul.thumbnails .thumbnail {
            position: relative;
        }

        body #fontSizeSelecter {
            float: left;
            margin: 2px 0 0 30px;
        }

        .dropuplist.dropup {
            margin: 0;
        }

        .popContainer {
            display: table;
            vertical-align: top;
        }

        .popContain {
            height: 100%;
            display: table-cell;
            /*vertical-align: top;*/
            vertical-align: middle;
        }

        .group1.cboxElement {
            display: block;
            float: left;
            position: relative;
        }

        .thumbnail .group1.cboxElement {
            float: none;
        }

        /* TOP MENU */

        .shortcutMenu {
            /*top: 0px;
            z-index: 9999;
            color: #999;
            border-radius: 0px 0px 4px 4px;
            position: relative;
            float: right;
            right: 30.9% !important;
            margin: 0 -15px;*/
            width: 100%;
        }

        .keyvisual {
            /*margin-top:-29px;*/
            width: 100%;
        }

        .moreBlock {
            margin-bottom: 15px;
        }

        .moreBlock a {
            float: none;
            display: table;
            /* vertical-align:middle; */
        }

        .moreBlock a .no-gutter {
            display: table-cell;
            /*  float:none;
            vertical-align:middle; */
        }

        @media (max-width: 750px) {
            .moreBlock {
                display: block;
                float: none;
                margin-bottom: 5px;
            }
        }

        /* responsive-table */
        .responsive-table-container {
            width: 100%;
            overflow-y: auto;
            margin: 0 0 1em;
        }

        .responsive-table-container::-webkit-scrollbar {
            width: 14px;
            height: 14px;
        }

        .responsive-table-container::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 3px solid #fff;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .responsive-table-container table {
            min-width: 100%;
            width: auto;
            white-space: nowrap;
        }

        @media (max-width: 550px) {
            .responsive-table-container {
                /* box-shadow: inset -10px -10px 30px -10px rgba(154, 154, 154, 0.6); */
            }
        }

        /* Image enlargeIcon*/
        .thumbnailCover {
            opacity: 0;
            background-color: #eaeaea;
            position: absolute;
        }
        .enlargeIcon {
            right: 0;
            width: 29px !important;
            height: 29px !important;
            position: absolute;
            margin: 5px !important;
        }
        .thumbnailCover:hover {
            opacity: 0.2;
            transition: opacity 0.55s ease-in-out;
        }

        @media (min-width: 750px) and (max-width: 1024px) {
            .enlargeIcon {
                right: 5px;
            }
        }

        .gotoTop {
            cursor: pointer;
            position: absolute;
            right: 10px;
            z-index: 998;
            bottom: 0;
            display: none;
        }

        .gotoTop .fa {
            font-size: 3em;
            color: #c6c6c6;
        }
        .gotoTop .fa:hover {
            opacity: 0.8;
        }

        @media (min-width: 767px) {
            .trivia-box {
                height: 150px;
            }

            .moreBlock .col-xs-9.no-gutter {
                height: 120px;
            }

            .trivia-box .btn-box-container {
                bottom: 0;
                position: absolute;
                right: 0;
            }
        }

        div#socialutility a:nth-child(2) {
            display: none !important;
        }
        span.fa.fa-weibo {
            display: none !important;
        }
    }
}
