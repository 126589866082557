.richText {
    :global {
        .mm-opening #headerMenu {
            display: none;
        }

        #headerMenu {
            position: fixed;
            height: 70px;
            display: block;
            z-index: 1000;
            text-align: center;
            color: #000;
            padding: 0;
            top: 0px;
            right: 0px;
        }

        #headerMenu-logo {
            position: absolute;
            height: 70px;
            display: block;
            text-align: center;
            color: #000;
            padding: 0;
            top: 0px;
            -webkit-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.3);
            -moz-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.3);
            box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.3);
            width: 100%;
        }

        body {
            color: #333333;
            font-family: 'arial', 'Roboto Condensed', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            font-size: 15px;
            line-height: 1.8em;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
            font-weight: 700;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            color: inherit;
        }

        h1 small,
        h2 small,
        h3 small,
        h4 small,
        h5 small,
        h6 small {
            font-weight: normal;
            line-height: 1;
            color: #614200;
        }

        h1 {
            color: #237c8c;
            font-size: 2em;
            padding-left: 0px;
            float: left;
            line-height: 40px;
            border-left: 0px solid #deb73d;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
        }

        h2 {
            color: #414140;
            font-size: 1.3125em;
            font-weight: 700;
            margin: 10px 0px 5px 0px;
            line-height: 1.5em;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
        }

        h3 {
            color: #003150;
            font-size: 1.125em;
            line-height: 1.1875em;
            font-weight: bold;
            margin: 10px 0px 10px 0px;
        }
        h3.social {
            color: #003150;
            font-size: 1.125em;
            line-height: 1.25em;
            font-weight: bold;
            margin: 8px 0px 10px 0px;
            font-family: 'open sans', 'Roboto Condensed', 'arial', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
        }

        h4 {
            color: #163985;
            font-size: 0.9375em;
            line-height: 1.6em;
            font-weight: bold;
        }

        h5 {
            color: #bf3100;
            font-size: 0.875em;
            line-height: 1.4em;
            font-weight: bold;
            margin-bottom: 5px;
        }

        h6 {
            color: #fff;
            font-size: 1em;
            font-weight: bold;
            margin-bottom: 5px;
        }

        a {
            color: #336699;
            text-decoration: none;
        }

        a:focus,
        a:hover {
            color: #333333;
            text-decoration: underline;
        }

        /* --------------  ghost-button ------------------ */
        .ghost-button-full-color {
            cursor: pointer;
            display: inline-block;
            padding: 8px 25px;
            color: #fff;
            /*background-color: transparent;*/
            background-color: rgba(0, 0, 0, 0.25);
            border: 2px solid #fff;
            text-align: center;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            line-height: normal;
            font-size: 20px;
            font-weight: 400;
            margin: 0 8px;
            letter-spacing: 0px;
            position: relative;

            /* width: 65% !important; */
            transition:
                color 0.3s ease-out,
                background-color 0.3s ease-out,
                border-color 0.3s ease-out;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
        }

        .ghost-button-full-color:hover,
        .ghost-button-full-color:active {
            background-color: #ffd200;
            border-color: #ffd200;
            color: #fff;
            text-decoration: none;
            transition:
                color 0.3s ease-in,
                background-color 0.3s ease-in,
                border-color 0.3s ease-in;
        }

        .ghost-button-full-color:visited,
        .ghost-button-full-color a:visied,
        .ghost-button-full-color a:focus {
            background-color: rgba(0, 0, 0, 0.25);
            border-color: #fff;
            color: #fff;
            text-decoration: none;
            transition:
                color 0.3s ease-in,
                background-color 0.3s ease-in,
                border-color 0.3s ease-in;
        }

        /* --------------  popup container ------------------ */
        #popupDetail {
            transform: scale(0.8);
        }

        .popup_visible #popupDetail {
            transform: scale(1);
        }

        .popContainer {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            max-width: 1170px;
        }

        .popContainer p {
            font-weight: 300;
            font-family: 'arial', 'Roboto Condensed', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            /*font-size: 1em;*/
            color: #fff;
            /*margin-top: 20px;*/
            margin-top: 10px;
            /* max-width: 700px; */
        }

        .popContain {
            margin: 0 20px;
        }

        .popContain h1 {
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            display: inline;
            font-size: 1.6em;
            font-weight: 300;
            color: #1abec7;
        }

        .popContain ul {
            color: #ffffff;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 20px;
            list-style-type: disc;
        }

        .overlayContainer {
            background: #000000;
            max-width: 550px;
        }

        .contentContainer {
            margin: 0;
            // background: #c23600 url(../images/1920-content-bg.jpg) repeat-y;
            background-size: 100%;
        }

        .focuspoint {
            height: 100%;
            width: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
        }

        .shareBtn {
            padding: 5px 10px;
        }

        .shareBtn .fa.fa-share-alt {
            font-size: 28px;
            text-shadow: none;
        }

        .jthumb {
            width: 30%;
        }

        .jkThumbGrp a {
            display: block;
            margin-bottom: 15px;
            padding: 0 10px;
            color: #b9480c;
        }

        .jkThumbGrp a img {
            width: 100%;
            padding: 5px;
        }

        .btnRgp {
            background: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            position: fixed;
            bottom: 0;
            width: 100px;
            display: block;
            text-align: center;
            z-index: 99999;
        }

        .btnRgp a {
            color: #666666;
        }

        #fp-nav ul li,
        .fp-slidesNav ul li {
            background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
            border-radius: 0px;
            height: 16px;
            margin: 7px;
            width: 14px;
        }

        body #fp-nav ul li .fp-tooltip {
            opacity: 1;
            width: auto;
            font-size: 12px;
            margin-right: -20px;
        }

        body #fp-nav ul li a span,
        .fp-slidesNav ul li a span {
            background: rgba(0, 0, 0, 0);
        }

        .socialBlock {
            float: left;
            width: 28%;
            margin: 1%;
        }

        /* Page Top menu */

        .indexpage div.pageTop {
            background: none;
            background: transparent;
            /*background:#ececec;
    border-bottom: 0px #DCBA3F solid; */
        }

        .indexContainer {
            margin: 0;
            /*background: #eaf6ec url(../images/1920-content-index-bg.gif);*/
            background-size: 100%;
        }

        .container-fluid {
            padding-right: 20px;
            padding-left: 20px;
            *zoom: 0;
            // background: #ffd958 url(../images/container-bg.jpg) repeat;
            padding-top: 20px;
            margin-top: 12px;
            margin-bottom: 15px;
            border-left: 0px solid #000000;
            border-right: 0px solid #000000;
        }

        .containerBackground {
            *zoom: 0;
            /*-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    background: rgba(255, 255, 255, 0.7) none repeat scroll 0px 0px;*/
            padding-right: 15px;
            padding-left: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .content-title-margin {
            margin-top: 5px;
            margin-bottom: 15px;
        }

        /* EWIN LOGO */
        .ewinLogo {
            position: relative;
            float: right;
            width: 170px;
            margin-right: 10px;
            z-index: 1;
        }

        .no-ewinLogo {
            position: relative;
            float: right;
            width: 25px;
            margin-right: 10px;
            z-index: 1;
        }

        .ewin_logo_all {
            float: right;
            height: 73px;
        }
        .ewin_logo_if {
            margin-left: 20px;
        }
        .ewin_logo {
            float: left;
            padding-top: 22px;
        }

        .ewin_logo_if .others {
            padding-top: 15px;
            float: left;
        }
        .ewin_logo_if .others div {
            height: 12px;
            margin-left: 4px;
        }

        .hkjcLogo {
            display: block;
            margin: 9px 0px 11px 0px;
            position: absolute;
            z-index: 9999;
        }

        .hkjcLogo img {
            display: block;
        }

        .shortcutMenu {
        }

        .shortcutMenu a {
            color: #229ed1;
            margin: 0px 5px;
            opacity: 1;
            font-weight: 300;
            font-size: 0.875em;
            line-height: 1em;
        }

        .shortcutMenu a:hover {
            color: #57bae4;
            transition:
                color 0.3s ease-in,
                background-color 0.3s ease-in;
            text-decoration: none;
        }

        .shortcutMenuHeadbar {
            background: none !important;
            top: 42px !important;
        }

        .shortcutMenuHeadbar a:hover {
            color: #000 !important;
            transition:
                color 0.3s ease-in,
                background-color 0.3s ease-in !important;
        }

        div#nextrace {
            background: rgba(110, 75, 45, 0.01) !important;
        }

        .footer-container {
            width: 100%;
            margin: 0;
            padding: 0 -15px !important;
            background-size: 100% auto;
            background-repeat: repeat-y;
            background-color: #414140;
        }

        .photo_fullwidth img {
            max-width: 100%;
        }

        #race-result {
            background: #eaeaea;
            padding-top: 10px;
        }

        .raceinfo-summary {
            background: #eaeaea;
            padding-top: 10px;
        }

        /* --------------  RGP style ------------------ */
        .rgp-container {
            color: #ffffff;
            font-size: 1em;
            text-align: left;
        }

        a.rgp-text {
            color: #ffffff;
            font-size: 1.2em;
        }

        a.rgp-text:hover {
            color: #ffd503;
            text-decoration: none;
        }

        .rgp-container ul li {
            color: #ffffff;
            font-size: 0.9em;
            list-style: disc;
        }

        .footer-margintop {
            bottom: -100px;
        }

        .container .footer-projectlist ul li {
            list-style: none;
        }

        // .360-view {
        //     display: none;
        // }

        .mobile-view {
            display: none;
        }

        /* --------------  panel-default style ------------------ */

        .panel-title {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 1em;
            color: inherit;
        }

        .panel-title a {
            text-decoration: none;
        }

        .panel-default {
            border-color: #ddd;
        }

        .panel-default > .panel-heading {
            color: #ffffff;
            background-color: #0c2d69;
            border-color: #ddd;
        }

        .panel-default > .panel-heading a:hover {
            color: #edf5e9;
        }

        .panel-default > .panel-heading a {
            color: #ffffff;
        }

        .panel-default > .panel-heading + .panel-collapse > .panel-body {
            border-top-color: #ddd;
        }

        .panel-default > .panel-heading .badge {
            color: #f5f5f5;
            background-color: #333;
        }

        .panel-default > .panel-footer + .panel-collapse > .panel-body {
            border-bottom-color: #ddd;
        }

        /* --------------  hr style ------------------ */

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
            border-right: 0px none;
            border-width: 1px 0px 0px;
            border-style: solid none none;
            border-color: #c3c3c3;
            -moz-border-top-colors: none;
            -moz-border-right-colors: none;
            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            border-image: none;
        }

        hr.header-line {
            margin-top: 5px;
        }

        /* --------------  caption style ------------------ */
        .caption {
            font-size: 0.8125em;
            color: #5a5a5a;
            line-height: 1.5em;
            padding-top: 5px;
        }

        /* =========== what's next ============== */
        .next-photo {
            /* border-left: 8px solid #229ed1; */
            padding-right: 10px;
            padding-left: 10px;
            padding-top: 0px;
            padding-bottom: 0px;
        }

        a.next-txt {
            color: #0c2d69;
            line-height: 1.25em;
            margin: 10px 0px 10px 0px;
        }

        a:hover .next-txt {
            color: #ff0000;
            line-height: 1.25em;
            margin: 10px 0px 10px 0px;
            text-decoration: underline;
        }

        h1.lastest {
            color: #0e4156;
            font-weight: 400;
            font-family: 'open sans', 'arial', 'Meiryo', 'Meiryo UI', 'Microsoft JhengHei UI',
                'Microsoft JhengHei', '微軟正黑體';
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 1.8em;
            /*padding: 10px;
    margin-top: 15px;
    border-left: #ff0000 solid 6px;*/
            float: left;
        }

        h3.lastest {
            color: #deb73d;
            font-weight: 400;
            font-family: 'open sans', 'arial', 'Meiryo', 'Meiryo UI', 'Microsoft JhengHei UI',
                'Microsoft JhengHei', '微軟正黑體';
            margin-top: 20px;
            margin-bottom: 10px;
            margin-left: 0px;
            margin-right: 5px;
            font-size: 1.6em;
            /*padding: 10px;
    margin-top: 15px;
    border-left: #ff0000 solid 6px;*/
            float: left;
            width: 100%;
        }

        .social-button {
            /*  bottom: 0%; position: absolute; text-align: right;*/
            /* display: block;
    margin-top: 15px;
    margin-right: 15px;
    z-index:9999; 
	position:absolute; 
    bottom:0;
    right:0; */
            margin: auto;
            width: 100%;
            padding: 0px;
            text-align: center;
        }

        /* ========== 2nd level ========== */
        .droplist {
            float: right;
        }

        .bcLv1 {
            color: #333333;
            border-bottom: 0px solid #b6b6b6;
            margin: 8px 0px;
            font-size: 0.9375em;
            min-height: 40px;
        }

        .content-bg {
            margin-top: 20px;
            /*margin-top:40px;*/
            /*padding: 10px;*/
            /* background: rgba(255, 255, 255, 1) none repeat scroll 0px 0px;*/
        }

        /* ========== race submenu ========== */
        .race-submenu {
            font-size: 0.9375em;
            line-height: 2em;
            /*background-position: right center;
    background-color: #696263;*/
            color: #333333;
            padding: 0px;
            margin-bottom: 10px;
        }

        .race-submenu a {
            color: #333333;
            padding: 7px;
        }

        .race-submenu a:hover {
            text-decoration: none;
            color: #ffffff;
            background-color: #cc0000;
        }

        .race-submenu > .active,
        .race-submenu > .active {
            padding: 5px;
            text-decoration: none;
            color: #ffffff;
            background-color: #cc0000;
        }

        /* ========== index feature ========== */
        .feature-date {
            font-size: 0.8125em;
            color: #5a5a5a;
            padding-left: 15px;
        }

        .feature-index-more {
            font-size: 0.875em;
            line-height: 1.25em;
            color: #b9480c;
        }

        h3.feature {
            color: #003366;
            font-size: 1em;
            line-height: 1.2em;
            margin: 0px;
            padding-left: 10px;
            border-left: #ff0000 solid 6px;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            padding-top: 10px;
            font-weight: bold;
        }

        h3.feature-title {
            color: #003366;
            font-size: 1em;
            line-height: 1.2em;
            margin: 0px;
            padding-left: 10px;
            border-left: #ff0000 solid 6px;
            font-family: 'Roboto Condensed', 'arial', 'open sans', 'Meiryo', 'Meiryo UI',
                'Microsoft JhengHei UI', 'Microsoft JhengHei', '微軟正黑體';
            padding-top: 5px;
            font-weight: 400;
        }

        .feature-box {
            position: relative;

            /* -webkit-border-radius: 0px 0px 8px 8px;
    -moz-border-radius: 0px 0px 8px 8px;
    border-radius: 0px 0px 8px 8px;

    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            background: rgba(255, 255, 255, 0.8) none repeat scroll 0px 0px;*/
            padding: 10px;
            float: left;
        }

        .feature-box a:hover {
            color: #fff;
            text-decoration: none;
        }

        .feature-text {
            color: #333;
            font-size: 0.9375em;
            line-height: 1.4em;
            text-align: left;
        }

        .followStarsBox .feature-text {
            color: #fff;
        }

        .feature-news-box {
            background-color: #cccccc;
            height: 240px;
            margin-bottom: 20px;
        }

        .feature-text-box {
            padding-top: 10px;
            margin-bottom: 10px;
        }

        .btn-box {
            font-size: 0.8125em;
            background-color: #deb73d;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #236467;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            -webkit-box-shadow: 1px 1px 1px 0px rgba(69, 69, 69, 0.5);
            -moz-box-shadow: 1px 1px 1px 0px rgba(69, 69, 69, 0.5);
            box-shadow: 1px 1px 1px 0px rgba(69, 69, 69, 0.5);
        }

        .btn-box:hover {
            color: #fff;
            background-color: #237c8c;
        }

        .btn-box-container {
            text-align: right;
            display: block;
        }

        .btn-news-box {
            font-size: 0.8125em;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: #19769a;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
        }

        .btn-news-box {
            font-size: 0.8125em;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: #229ed1;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
        }

        .btn-news-box:hover {
            font-size: 0.8125em;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: #57bae4;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
        }

        .btn-news-box-container {
            text-align: right;
            display: block;
        }

        /* ========== follow the star index ========== */

        .indexFeature-box {
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            background-color: aquamarine;
        }

        /* ========== social share & fontChange ========== */
        .socialutility {
            float: right;
        }

        .iconsContainer * {
            /*float: right;*/
            margin-top: 3px;
        }

        @media all and (max-width: 480px) {
            .iconsContainer * {
                margin-top: 10px;
            }
            .social-float {
                float: left;
            }
            .next-photo {
                padding-right: 0px;
                padding-left: 0px;
            }
        }

        .social-float {
            float: right;
            margin-top: 10px;
            margin-bottom: 10px;
            min-width: 280px;
        }

        /* fontChange */
        body #fontSizeSelecter {
            background: none;
        }

        #fontSizeSelecter a {
            color: #999999 !important;
        }

        #fontSizeSelecter a:hover {
            color: #3b3b3b !important;
            background: none !important;
            transition: 0.25s ease-out;
            border-radius: 4px 4px 4px 4px;
        }

        #fontSizeSelecter a.active {
            background: #949494 !important;
            border-radius: 4px 4px 4px 4px;
        }

        #fontSizeSelecter span {
            color: #333333 !important;
        }

        /* race */
        .race-hightlight {
            display: inline-block;
            padding: 2px 30px;
            width: 100%;
            color: #237c8c;
            font-weight: bold;
            font-size: 1.2em;
            line-height: 1.3em;
            /*background-color: #0e4156;*/
            outline: medium none;
            text-decoration: none;
            border-radius: 7px;
            margin: 0px;
            letter-spacing: 0px;
            position: relative;
            transition:
                color 0.3s ease-out 0s,
                background-color 0.3s ease-out 0s,
                border-color 0.3s ease-out 0s;
            font-family: 'arial', 'open sans', 'Meiryo', 'Meiryo UI', 'Microsoft JhengHei UI',
                'Microsoft JhengHei', '微軟正黑體';
            padding: 5px;
        }

        .race-icon {
            margin-right: 10px;
            margin-left: 10px;
        }

        table.pastwinnerlist td {
            /*color: #666;*/
            border-bottom: 1px solid #ddd;
            padding: 7px 3px;
        }

        table.pastwinnerlist th {
            /*color: #666;*/
            border-bottom: 2px solid #ddd;
            padding: 7px 3px;
        }

        .racedata {
            width: 100%;
        }

        /* pagination */
        .pagination {
            margin: 20px 0;
        }

        .pagination ul {
            display: inline-block;
            *display: inline;
            margin-bottom: 0;
            margin-left: 0;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            *zoom: 1;
            /*
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    */
        }

        .pagination ul > li {
            display: inline;
        }

        .pagination ul > li > a,
        .pagination ul > li > span {
            color: #0c2d69;
            float: left;
            padding: 4px 12px;
            line-height: 20px;
            text-decoration: none;
            background-color: #ffffff;
            border: 1px solid #e0e0e0;
            border-left-width: 0;
        }

        .pagination ul > li > a:hover,
        .pagination ul > .active > a,
        .pagination ul > .active > span {
            color: #ffffff;
            background-color: #0c2d69;
        }

        .pagination ul > .active > a:hover,
        .pagination ul > .active > span {
            color: #999999;
            background-color: #ffffff;
        }

        .pagination ul > .active > a,
        .pagination ul > .active > span {
            color: #fff;
            cursor: default;
        }

        .pagination ul > .disabled > span,
        .pagination ul > .disabled > a,
        .pagination ul > .disabled > a:hover {
            color: #999999;
            cursor: default;
            background-color: transparent;
        }

        .pagination ul > li:first-child > a,
        .pagination ul > li:first-child > span {
            border-left-width: 1px;
            -webkit-border-bottom-left-radius: 4px;
            border-bottom-left-radius: 4px;
            -webkit-border-top-left-radius: 4px;
            border-top-left-radius: 4px;
            -moz-border-radius-bottomleft: 4px;
            -moz-border-radius-topleft: 4px;
        }

        .pagination ul > li:last-child > a,
        .pagination ul > li:last-child > span {
            -webkit-border-top-right-radius: 4px;
            border-top-right-radius: 4px;
            -webkit-border-bottom-right-radius: 4px;
            border-bottom-right-radius: 4px;
            -moz-border-radius-topright: 4px;
            -moz-border-radius-bottomright: 4px;
        }

        .pagination-centered {
            text-align: center;
        }

        .pagination-right {
            text-align: right;
        }

        .pagination-large ul > li > a,
        .pagination-large ul > li > span {
            padding: 11px 19px;
            font-size: 17.5px;
        }

        .pagination-large ul > li:first-child > a,
        .pagination-large ul > li:first-child > span {
            -webkit-border-bottom-left-radius: 6px;
            border-bottom-left-radius: 6px;
            -webkit-border-top-left-radius: 6px;
            border-top-left-radius: 6px;
            -moz-border-radius-bottomleft: 6px;
            -moz-border-radius-topleft: 6px;
        }

        .pagination-large ul > li:last-child > a,
        .pagination-large ul > li:last-child > span {
            -webkit-border-top-right-radius: 6px;
            border-top-right-radius: 6px;
            -webkit-border-bottom-right-radius: 6px;
            border-bottom-right-radius: 6px;
            -moz-border-radius-topright: 6px;
            -moz-border-radius-bottomright: 6px;
        }

        .pagination-mini ul > li:first-child > a,
        .pagination-small ul > li:first-child > a,
        .pagination-mini ul > li:first-child > span,
        .pagination-small ul > li:first-child > span {
            -webkit-border-bottom-left-radius: 3px;
            border-bottom-left-radius: 3px;
            -webkit-border-top-left-radius: 3px;
            border-top-left-radius: 3px;
            -moz-border-radius-bottomleft: 3px;
            -moz-border-radius-topleft: 3px;
        }

        .pagination-mini ul > li:last-child > a,
        .pagination-small ul > li:last-child > a,
        .pagination-mini ul > li:last-child > span,
        .pagination-small ul > li:last-child > span {
            -webkit-border-top-right-radius: 3px;
            border-top-right-radius: 3px;
            -webkit-border-bottom-right-radius: 3px;
            border-bottom-right-radius: 3px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 3px;
        }

        .pagination-small ul > li > a,
        .pagination-small ul > li > span {
            padding: 2px 10px;
            font-size: 11.9px;
        }

        .pagination-mini ul > li > a,
        .pagination-mini ul > li > span {
            padding: 1px 6px;
            font-size: 10.5px;
        }

        .cheerbox {
            background-color: #fff;
            padding: 15px;
        }

        /* Quiz CSS*/

        .quiz-box {
            position: relative;

            -webkit-border-radius: 0px 0px 8px 8px;
            -moz-border-radius: 0px 0px 8px 8px;
            border-radius: 0px 0px 8px 8px;

            -webkit-box-shadow:
                0 1px 4px rgba(0, 0, 0, 0.3),
                0 0 40px rgba(0, 0, 0, 0.1) inset;
            -moz-box-shadow:
                0 1px 4px rgba(0, 0, 0, 0.3),
                0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:
                0 1px 4px rgba(0, 0, 0, 0.3),
                0 0 40px rgba(0, 0, 0, 0.1) inset;
            background: rgba(255, 255, 255, 0.8) none repeat scroll 0px 0px;
            padding: 0px;
            float: left;
        }

        .padding-20 {
            padding: 30px;

            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 10px;
        }
        .phone-in-text {
            margin-top: 20px;
        }

        .phone-in-date {
            margin-top: 0px;
            margin-bottom: 10px;
        }

        .subtitle-quest {
            color: #0e4156;
            font-size: 2em;
            font-weight: bold;
        }

        .subtitle-quest-icon {
            color: #0e4156;
            font-size: 10em;
            font-weight: bold;
        }

        .phone-in-quest {
            font-size: 0.8125em;
            background-color: #c6eeff;
            padding-left: 18px;
            padding-right: 18px;
            padding-top: 14px;
            padding-bottom: 14px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: 1px 1px 1px 0px rgba(69, 69, 69, 0.5);
            -moz-box-shadow: 1px 1px 1px 0px rgba(69, 69, 69, 0.5);
            box-shadow: 1px 1px 1px 0px rgba(69, 69, 69, 0.5);
        }

        table.branch51 td {
            border-bottom: 1px solid #666666;
            padding: 7px 3px;
            color: #ffffff;
        }

        table.branch51 th {
            background: #237c8c;
            padding: 5px 10px;
            font-weight: bold;
            color: #ffffff;
        }

        /* Tee CSS*/

        .stepsForm {
            width: 100%;
            box-sizing: border-box;
            line-height: 1;
        }
        .stepsForm .sf-error {
            border: solid 1px #c00 !important;
        }
        .stepsForm .sf-error + span {
            border-color: #c00 !important;
        }
        .stepsForm #sf-msg {
            display: inline-block;
            padding: 1rem;
        }
        .stepsForm .sf-msg-error {
            color: #c00;
        }
        .stepsForm .sf-msg-success {
            color: #090;
        }
        .stepsForm .sf-steps {
            width: 100%;
            box-sizing: border-box;
            height: auto;
            padding: 0 1rem;
        }
        .stepsForm .sf-steps .sf-steps-content {
            width: 100%;
            box-sizing: border-box;
            border-bottom: solid 1px #0e4156;
            font-size: 14px;
        }
        .stepsForm .sf-steps-content > div {
            display: inline-block;
            padding: 1rem 2rem 1rem 4rem;
            background: #ccc;
            color: #fff;
            position: relative;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            overflow: hidden;
            padding-left: 50px;
        }
        .stepsForm .sf-steps-center > div {
            display: block;
            padding: 1rem 2rem 1rem 4rem;
            background: #ccc;
            color: #fff;
            cursor: pointer;
            position: relative;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            overflow: hidden;
            box-sizing: border-box;
        }
        .stepsForm .sf-steps-content > div.sf-active {
            background: #3b7891;
        }
        .stepsForm .sf-steps-content > div > span {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: #aaa;
            padding: 0.8rem 1rem;
            font-size: 20px;
        }
        .stepsForm .sf-steps-content > div > i {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            // background: url(../img/sf-steps-activated.png) center no-repeat;
            padding: 1rem;
        }
        .stepsForm .sf-steps-content > div.sf-active > span {
            background: #0e4156;
        }
        .stepsForm .sf-steps-content > div > span:after {
            content: '';
            display: block;
            width: 10px;
            position: absolute;
            right: -10px;
            height: 100%;
            top: 0;
            background: url('data:image/svg+xml; charset=utf-8,<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20" preserveAspectRatio="none"><path d="m0,0 l10,10 l-10,10 l-0,-20z" stroke-width="1.5" fill="#AAA"/></svg>')
                0 0 no-repeat;
        }
        .stepsForm .sf-steps-content > div.sf-active > span:after {
            background: url('data:image/svg+xml; charset=utf-8,<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20" preserveAspectRatio="none"><path d="m0,0 l10,10 l-10,10 l-0,-20z" stroke-width="1.5" fill="#0e4156"/></svg>')
                0 0 no-repeat;
        }

        .stepsForm .sf-steps-form {
            display: block;
            position: relative;
        }

        .stepsForm .sf-steps-navigation {
            padding: 0 1rem;
        }

        .stepsForm .sf-button {
            padding: 1rem 2rem;
            background: #2096cd;
            color: #fff;
            border: none;
            outline: none;
            cursor: pointer;
        }

        .stepsForm .sf-content {
            width: 100%;
            box-sizing: border-box;
            display: none;
            overflow: hidden;
            padding: 0;
            margin: 0;
            list-style-type: none;
            margin-top: 30px;
        }
        .stepsForm .sf-content > li {
            width: 100%;
            height: auto;
            overflow: hidden;
            margin-bottom: 2rem;
            padding: 3px 0;
        }
        .stepsForm input[type='text'],
        .stepsForm input[type='tel'],
        .stepsForm input[type='email'],
        .stepsForm input[type='password'] {
            width: 100%;
            box-sizing: border-box;
            padding: 1rem;
            border: solid 1px #ccc;
            outline: none;
        }
        .stepsForm textarea {
            width: 100%;
            height: 100px;
            box-sizing: border-box;
            padding: 1rem;
            border: solid 1px #ccc;
            outline: none;
            overflow: auto;
        }
        .stepsForm label.sf-select {
            position: relative;
            display: block;
        }
        .stepsForm label.sf-select > select {
            width: 100%;

            box-sizing: border-box;
            padding: 1rem;
            border: solid 1px #ccc;
            outline: none;
        }
        .stepsForm label.sf-select > span {
            position: absolute;
            display: inline-block;
            text-align: center;
            right: 1px;
            top: 1px;
            bottom: 1px;
            width: 3.5rem;
            pointer-events: none;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #e8e8e8;
            border-left: solid 1px #ddd;
        }
        .stepsForm label.sf-select > span::after {
            content: '';
            display: inline-block;
            width: 20%;
            height: 20%;
            -webkit-transform: rotate(-45deg) translate(-80%, 80%);
            -moz-transform: rotate(-45deg) translate(-80%, 80%);
            -ms-transform: rotate(-45deg) translate(-80%, 80%);
            -o-transform: rotate(-45deg) translate(-80%, 80%);
            transform: rotate(-45deg) translate(-80%, 80%);
            border: 0 solid #fff;
            border-left-width: 0.25em;
            border-bottom-width: 0.25em;
        }
        .stepsForm label.sf-select > select:focus + span {
            background-color: #2096cd;
        }
        .stepsForm .sf-radio,
        .stepsForm .sf-check {
            width: 100%;
            height: auto;
            padding: 1rem 0;
        }
        .stepsForm .sf-radio label,
        .stepsForm .sf-check label {
            display: inline-block;
            margin-right: 20px;
        }
        .stepsForm .sf-radio label input[type='radio'],
        .stepsForm .sf-check label input[type='checkbox'] {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            margin: 0;
            padding: 0;
            height: 10px;
        }
        .stepsForm .sf-radio label input[type='radio'] + span,
        .stepsForm .sf-check label input[type='checkbox'] + span {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: #fff;
            border: 1px solid #cecece;
            text-align: center;
            vertical-align: top;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
        }
        .stepsForm .sf-radio label input[type='radio'] + span::after,
        .stepsForm .sf-check label input[type='checkbox'] + span::after {
            content: '';
            display: inline-block;
            opacity: 0;
            width: 100%;
            height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
        }
        .stepsForm .sf-radio label input[type='radio']:focus + span,
        .stepsForm .sf-check label input[type='checkbox']:focus + span,
        .stepsForm .sf-radio label input[type='radio']:active + span,
        .stepsForm .sf-check label input[type='checkbox']:active + span {
            border-color: #2096cd;
        }
        .stepsForm .sf-radio label input[type='radio']:checked + span::after,
        .stepsForm .sf-check label input[type='checkbox']:checked + span::after {
            opacity: 1;
        }
        .stepsForm .sf-radio label input[type='radio'].error + span,
        .stepsForm .sf-check label input[type='checkbox'].error + span {
            border-color: #e2513f;
            border-width: 2px;
        }
        .stepsForm .sf-radio label input[type='radio'] + span {
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
            -moz-box-shadow: 0 0 5px #ddd;
            -webkit-box-shadow: 0 0 5px #ddd;
            box-shadow: 0 0 5px #ddd;
        }
        .stepsForm .sf-radio label input[type='radio'] + span::after {
            -webkit-border-radius: 1em;
            -moz-border-radius: 1em;
            border-radius: 1em;
            background-color: #2096cd;
        }
        .stepsForm .sf-radio label input[type='radio']:checked + span::after {
            border: 0.2em solid #fff;
        }
        .stepsForm .sf-check label input[type='checkbox'] + span {
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            -moz-box-shadow: 0 0 5px #ddd;
            -webkit-box-shadow: 0 0 5px #ddd;
            box-shadow: 0 0 5px #ddd;
        }
        .stepsForm .sf-check label input[type='checkbox'] + span::after {
            height: 45%;
            width: 75%;
            background-color: transparent;
            border: 0 solid #2096cd;
            vertical-align: bottom;
            -webkit-transform: rotate(-45deg) translate(50%, -75%);
            -moz-transform: rotate(-45deg) translate(50%, -75%);
            -ms-transform: rotate(-45deg) translate(50%, -75%);
            -o-transform: rotate(-45deg) translate(50%, -75%);
            transform: rotate(-45deg) translate(50%, -75%);
        }
        .stepsForm .sf-check label input[type='checkbox']:checked + span::after {
            border-left-width: 0.2em;
            border-bottom-width: 0.2em;
        }
        .stepsForm input[type='text']:focus,
        .stepsForm input[type='tel']:focus,
        .stepsForm input[type='password']:focus,
        .stepsForm input[type='email']:focus,
        .stepsForm label.sf-select > select:focus,
        .stepsForm textarea:focus {
            border-color: #2096cd;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(32, 150, 205, 1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(32, 150, 205, 1);
            box-shadow: 0px 0px 5px 0px rgba(32, 150, 205, 1);
        }
        .stepsForm input,
        .stepsForm select,
        .stepsForm textarea,
        .stepsForm button {
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
        }
        .sf-shadow input,
        .sf-shadow select,
        .sf-shadow textarea {
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
        .stepsForm .sf_columns {
            min-height: 30px;
            display: inline-block;
            padding: 0 1rem;
            box-sizing: border-box;
            float: left;
        }
        .sf-align-right {
            text-align: right;
        }

        .sf-align-center {
            text-align: center;
        }
        .sf-align-center > span#sf-msg {
            display: block;
            clear: both;
            margin: 0 auto;
        }
        .sf-align-center > button {
            display: block;
            clear: both;
            margin: 0 auto;
            margin-bottom: 10px;
        }

        .stepsForm .column_1 {
            width: 16.666666667%;
        }
        .stepsForm .column_2 {
            width: 33.333333334%;
        }
        .stepsForm .column_3 {
            width: 50%;
        }
        .stepsForm .column_4 {
            width: 66.666666667%;
        }
        .stepsForm .column_5 {
            width: 83.333333334%;
        }
        .stepsForm .column_6 {
            width: 100%;
        }

        /*Trivia*/
        .trivia-box {
            width: 100%;
            /* border-left: 8px solid #229ED1; */
            padding-right: 10px;
        }
        .trivia-text {
            font-size: 1.5em;
            line-height: 1.3em;
            color: #229ed1;
        }

        .more-left {
            padding-left: 10px;
        }

        /*top banner height*/

        .top-banner-height {
            height: 70px;
            /*background-color:#f1f1f1;*/
        }
        /* sync all drop down */
        .dropdown-menu-contentpage li {
            border-bottom: 1px solid #ebebeb !important;
        }
    }
}
